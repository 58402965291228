import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleModel } from '../../../core/models/article/article.model';
import { ArticleService } from '../../../core/services/article/article.service';

@Component({
  selector: 'app-articles-grid',
  templateUrl: './articles-grid.component.html',
  styleUrls: ['./articles-grid.component.scss']
})
export class ArticlesGridComponent implements OnInit {
  articles: Observable<ArticleModel[]>;

  constructor(private articleService: ArticleService) { }

  ngOnInit() {
    this.articles = this.articleService.getArticles();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminArticlesRoutingModule } from './admin-articles-routing.module';
import { AdminArticlesListComponent } from './admin-articles-list/admin-articles-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { AdminAddArticleComponent } from './admin-add-article/admin-add-article.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArticleService } from '../../core/services/article/article.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AdminDeleteArticleComponent } from './admin-delete-article/admin-delete-article.component';
import { AdminEditArticleComponent } from './admin-edit-article/admin-edit-article.component';

@NgModule({
  declarations: [
    AdminArticlesListComponent,
    AdminAddArticleComponent,
    AdminDeleteArticleComponent,
    AdminEditArticleComponent
  ],
  imports: [
    CommonModule,
    AdminArticlesRoutingModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AngularFirestore,
    ArticleService
  ],
  entryComponents: [
    AdminDeleteArticleComponent
  ]
})
export class AdminArticlesModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminArticlesListComponent } from './admin-articles-list/admin-articles-list.component';
import { AdminAddArticleComponent } from './admin-add-article/admin-add-article.component';
import { AuthGuard } from '../../auth/auth.guard';
import { AdminEditArticleComponent } from './admin-edit-article/admin-edit-article.component';

const routes: Routes = [
  {
    path: '',
    component: AdminArticlesListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add',
    canActivate: [AuthGuard],
    component: AdminAddArticleComponent
  },
  {
    path: 'edit/:id',
    canActivate: [AuthGuard],
    component: AdminEditArticleComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminArticlesRoutingModule { }

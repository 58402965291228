import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { AdminVideosListComponent } from './admin-videos-list/admin-videos-list.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AdminVideosListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminVideosRoutingModule { }

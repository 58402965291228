import { Component, Input, OnInit } from '@angular/core';
import { ArticleModel as Article } from '../../../../core/models/article/article.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-article-present',
  templateUrl: './article-present.component.html',
  styleUrls: ['./article-present.component.scss']
})
export class ArticlePresentComponent implements OnInit {
  @Input()
  article = {} as Article;

  constructor(private titleService: Title) {}

  ngOnInit() {
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

}

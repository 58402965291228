import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ArticleModel } from '../../../core/models/article/article.model';
import { ArticleService } from '../../../core/services/article/article.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-articles-details',
  templateUrl: './articles-details.component.html',
  styleUrls: ['./articles-details.component.scss']
})
export class ArticlesDetailsComponent implements OnInit, OnDestroy {
  article = {} as ArticleModel;
  articleId: string;
  private unsubscribe$ = new Subject<void>();

  constructor(private articleService: ArticleService,
              private activatedRoute: ActivatedRoute,
              private location: Location) { }

  ngOnInit() {
    this.articleId = this.activatedRoute.snapshot.paramMap.get('id');

    if (this.articleId) {
      this.articleService.getArticleById(this.articleId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            this.article = Object.assign(this.article, response.data());
          }
        );
    }
  }

  goBack() {
    return this.location.back();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

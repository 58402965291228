import { Component, Input, OnInit } from '@angular/core';
import { ArticleModel as Article } from '../../../../core/models/article/article.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-article-box',
  templateUrl: './article-box.component.html',
  styleUrls: ['./article-box.component.scss']
})
export class ArticleBoxComponent implements OnInit {
  @Input()
  article = {} as Article;

  constructor(private titleService: Title) { }

  ngOnInit() {
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}

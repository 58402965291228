import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgSelectModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    EditorModule
  ],
  exports: [
    NgbModule,
    NgSelectModule,
    AngularFireModule,
    AngularFireAuthModule,
    EditorModule
  ]
})
export class SharedModule { }

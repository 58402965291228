import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { AppRoutingModule } from '../../app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AsideNavComponent,
    MainLayoutComponent,
    MobileNavComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    SharedModule,
    NgbModule,
  ]
})
export class LayoutModule { }

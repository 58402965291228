import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../../../core/services/article/article.service';
import { ArticleModel } from '../../../core/models/article/article.model';
import { Observable } from 'rxjs';
import { AdminDeleteArticleComponent } from '../admin-delete-article/admin-delete-article.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-articles-list',
  templateUrl: './admin-articles-list.component.html',
  styleUrls: ['./admin-articles-list.component.scss']
})
export class AdminArticlesListComponent implements OnInit {
  articles: Observable<ArticleModel[]>;
  closeResult: string;
  modalRef: any;

  constructor(private articleService: ArticleService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.articles = this.articleService.getArticles();
  }

  showDeleteModal(article: ArticleModel): void {
    this.modalRef = this.modalService.open(AdminDeleteArticleComponent);
    this.modalRef.componentInstance.article = article;
    this.modalRef.componentInstance.modalRef = this.modalRef;

    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {});
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ArticleModel as Article } from '../../../core/models/article/article.model';
import { ArticleService } from '../../../core/services/article/article.service';

@Component({
  selector: 'app-admin-delete-article',
  templateUrl: './admin-delete-article.component.html',
  styleUrls: ['./admin-delete-article.component.scss']
})
export class AdminDeleteArticleComponent implements OnInit {

  @Input()
  article: Article;

  @Input()
  modalRef: any;

  constructor(private articleService: ArticleService) { }

  ngOnInit() {
  }

  delete(article: Article): void {
    this.articleService.deleteArticle(article);
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.close();
  }
}

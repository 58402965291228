import { Component, OnInit } from '@angular/core';
import {ArticleModel as Article, Tag} from '../../../core/models/article/article.model';
import { Observable } from 'rxjs';
import { ArticleService } from '../../../core/services/article/article.service';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss']
})
export class ArticlesListComponent implements OnInit {
  articles: Observable<Article[]>;
  tags: Tag[];

  constructor(private articleService: ArticleService) {
  }

  ngOnInit() {
    this.articles = this.articleService.getArticles();

    this.tags = [
      { id: 1, name: 'HTML' },
      { id: 2, name: 'CSS' },
      { id: 3, name: 'SASS' },
      { id: 4, name: 'LESS' },
      { id: 5, name: 'JavaScript' },
      { id: 6, name: 'PHP' },
      { id: 7, name: 'Laravel' }
    ];

  }

  getArticlesByTag(tag: string): void {
    this.articles = this.articleService.getArticlesByTagName(tag);
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticlesGridComponent } from './articles-grid/articles-grid.component';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { ArticlesDetailsComponent } from './articles-details/articles-details.component';

const routes: Routes = [
  { path: '', component: ArticlesGridComponent },
  { path: 'articles', component: ArticlesListComponent },
  { path: 'article/:id', component: ArticlesDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticlesRoutingModule { }

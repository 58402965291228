import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminDashboardNavComponent } from './admin-layout/admin-dashboard-nav/admin-dashboard-nav.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AdminArticlesModule } from './admin-articles/admin-articles.module';
import { AdminVideosModule } from './admin-videos/admin-videos.module';


@NgModule({
  declarations: [
    AdminDashboardNavComponent,
    AdminLayoutComponent,
    AdminDashboardNavComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    TranslateModule,
    AdminArticlesModule,
    AdminVideosModule
  ],
  exports: [
    AdminRoutingModule
  ],
  providers: [
  ]
})
export class AdminModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { ArticlesGridComponent } from './articles-grid/articles-grid.component';
import { ArticlesRoutingModule } from './articles-routing.module';
import { ArticleBoxComponent } from './articles-grid/article-box/article-box.component';
import { ArticlePresentComponent } from './articles-list/article-present/article-present.component';
import { TranslateModule } from '@ngx-translate/core';
import { ArticleService } from '../../core/services/article/article.service';
import { ArticlesDetailsComponent } from './articles-details/articles-details.component';
import { SaveHtmlPipe } from '../../shared/pipes/save-html/save-html.pipe';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    ArticlesListComponent,
    ArticlesGridComponent,
    ArticleBoxComponent,
    ArticlePresentComponent,
    ArticlesDetailsComponent,
    SaveHtmlPipe
  ],
  imports: [
    CommonModule,
    ArticlesRoutingModule,
    TranslateModule,
    SharedModule
  ],
  exports: [
  ],
  providers: [
    ArticleService
  ]
})
export class ArticlesModule { }

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(private authService: AuthService, private fb: FormBuilder, private toastrService: ToastrService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.fb.group({
      email:    [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  login() {
    if (this.loginForm.get('email').errors || this.loginForm.get('password').errors) {
      this.toastrService.error('Всички полета са задължителни!', 'Грешка в логина!', {
        timeOut: 5000
      });
    } else {
      this.authService.login(this.loginForm.get('email').value, this.loginForm.get('password').value)
        .then(
          resp => {
            this.toastrService.success('Успешна аутентикация!');
          }
        )
        .catch(error => {
          switch (error.code) {
            case 'auth/invalid-email':
              this.toastrService.error('Въвели сте невалиден синтаксис в полето Email!', 'Грешка в логина!', {
                timeOut: 5000
              });
              break;
            case 'auth/user-not-found':
              this.toastrService.error('Потребител с тези данни не съшествува!', 'Грешка в логина!', {
                timeOut: 5000
              });
              break;
            case 'auth/wrong-password':
              this.toastrService.error('Въвели сте грешен Email адрес или парола!', 'Грешка в логина!', {
                timeOut: 5000
              });
              break;
            case 'auth/too-many-requests':
              this.toastrService.error('Направили сте прекалено много неуспешни опити! Опитайте пак след 5 мин.', 'Грешка в логина!', {
                timeOut: 5000
              });
              break;
          }
        });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArticleModel as Article, Tag } from '../../../core/models/article/article.model';
import { ArticleService } from '../../../core/services/article/article.service';

@Component({
  selector: 'app-admin-add-article',
  templateUrl: './admin-add-article.component.html',
  styleUrls: ['./admin-add-article.component.scss']
})

export class AdminAddArticleComponent implements OnInit {
  articleForm: FormGroup;
  tags: Tag[];
  article = {} as Article;

  tinyPlugins: Array<any>;

  constructor(private fb: FormBuilder,
              private articleService: ArticleService) {}

  ngOnInit() {
    this.tags = [
      { id: 1, name: 'HTML' },
      { id: 2, name: 'CSS' },
      { id: 3, name: 'SASS' },
      { id: 4, name: 'LESS' },
      { id: 5, name: 'JavaScript' },
      { id: 6, name: 'PHP' },
      { id: 7, name: 'Laravel' }
    ];

    this.tinyPlugins = [
      'link',
      'image',
      'lists',
      'media',
      'template',
      'fullscreen',
      'codesample',
    ];

    this.buildForm();
  }

  buildForm(): void {
    this.articleForm = this.fb.group({
      title: [],
      subtitle: [],
      coverImage: [],
      description: [],
      text: [],
      tags: [],
      author: ['Светослав Тошкин'],
      active: []
    });

    this.articleForm.get('author').disable();
  }

  onSubmit() {
    this.article.date = new Date();
    this.article = Object.assign(this.article, this.articleForm.value);

    this.articleService.addArticle(this.article);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArticleModel, Tag } from '../../../core/models/article/article.model';
import { ArticleService } from '../../../core/services/article/article.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-edit-article',
  templateUrl: './admin-edit-article.component.html',
  styleUrls: ['./admin-edit-article.component.scss']
})
export class AdminEditArticleComponent implements OnInit, OnDestroy {

  articleForm: FormGroup;
  tags: Tag[];
  article = {} as ArticleModel;
  articleId: string;
  private unsubscribe$ = new Subject<void>();
  tinyPlugins: Array<any>;

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private articleService: ArticleService) {}

  ngOnInit() {
    this.articleId = this.activatedRoute.snapshot.paramMap.get('id');

    this.tags = [
      { id: 1, name: 'HTML' },
      { id: 2, name: 'CSS' },
      { id: 3, name: 'SASS' },
      { id: 4, name: 'LESS' },
      { id: 5, name: 'JavaScript' },
      { id: 6, name: 'PHP' },
      { id: 7, name: 'Laravel' }
    ];

    this.tinyPlugins = [
      'link',
      'image',
      'lists',
      'media',
      'template',
      'fullscreen',
      'codesample'
    ];

    this.buildForm();

    if (this.articleId) {
      this.articleService.getArticleById(this.articleId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.article = Object.assign(this.article, response.data());
        },
        (error) => {
        },
        () => {
          this.articleForm.get('title').setValue(this.article.title);
          this.articleForm.get('subtitle').setValue(this.article.subtitle);
          this.articleForm.get('coverImage').setValue(this.article.coverImage);
          this.articleForm.get('description').setValue(this.article.description);
          this.articleForm.get('text').setValue(this.article.text);
          this.articleForm.get('tags').setValue(this.article.tags);
          this.articleForm.get('active').setValue(this.article.active);
        }
      );
    }
  }

  buildForm(): void {
    this.articleForm = this.fb.group({
      title: [],
      subtitle: [],
      coverImage: [],
      description: [],
      text: [],
      tags: [],
      author: ['Светослав Тошкин'],
      active: []
    });
  }

  onSubmit() {
    this.article = Object.assign(this.article, this.articleForm.value);
    this.articleService.updateArticle(this.articleId, this.article);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

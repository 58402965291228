import { Injectable } from '@angular/core';
import { ArticleModel as Article } from '../../models/article/article.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class ArticleService {
  private articlesCollection: AngularFirestoreCollection<Article>;
  articles$: Observable<Article[]>;

  constructor(private afs: AngularFirestore,
              private router: Router,
              public toastrService: ToastrService) {
  }

  getArticles(): Observable<Article[]> {
    this.articlesCollection = this.afs.collection<Article>('articles', ref => ref.orderBy('date', 'desc'));

    return this.articles$ = this.articlesCollection.snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Article;
          return data;
        }))
      );
  }

  getArticleById(id: string) {
    const article$ = this.afs.collection<Article>('articles').doc(id).get();
    return article$;
  }

  getArticlesByTagName(tag: string) {
    this.articlesCollection = this.afs.collection<Article>(
      'articles', ref => ref.where('tags', 'array-contains', tag)
    );

    return this.articles$ = this.articlesCollection.snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Article;
          return data;
        }))
      );
  }

  addArticle(article: Article) {
    const id = this.afs.createId();
    article.id = id;

    this.articlesCollection.doc(id).set(article);

    this.router.navigate(['/admin/dashboard/articles']);
    this.toastrService.success('Статията беше добавена успешно!');
  }

  updateArticle(articleId: string, article: Article) {
    this.articlesCollection.doc(articleId).update(article);

    this.router.navigate(['/admin/dashboard/articles']);
    this.toastrService.success('Статията беше обновена успешно!');
  }

  deleteArticle(article: Article): void {
    this.articlesCollection.doc(article.id).delete().then(() => {
      this.toastrService.success('Статията беше изтрита успешно!');
    }).catch((error) => {
      console.error('Error removing document: ', error);
    });
  }
}

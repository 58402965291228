import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminVideosRoutingModule } from './admin-videos-routing.module';
import { AdminVideosListComponent } from './admin-videos-list/admin-videos-list.component';
import {TranslateModule} from '@ngx-translate/core';
import { AdminAddVideoComponent } from './admin-add-video/admin-add-video.component';

@NgModule({
  declarations: [AdminVideosListComponent, AdminAddVideoComponent],
  imports: [
    CommonModule,
    AdminVideosRoutingModule,
    TranslateModule
  ]
})
export class AdminVideosModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
  {
    path: 'articles',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./admin-articles/admin-articles.module').then(m => m.AdminArticlesModule),
  },

  {
    path: 'videos',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./admin-videos/admin-videos.module').then(m => m.AdminVideosModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
